import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "root/components/Typography";
import partnersPT from "root/data/partners-pt.json";
import partnersES from "root/data/partners-es.json";

import { useTranslation } from "react-i18next";
import styles from "./index.module.css";

const SolutionPartners = ({ color }) => {
  const { t, i18n } = useTranslation();
  const data = i18n.language === "es-ES" ? partnersES : partnersPT;

  const [origin, setOrigin] = useState("");

  useEffect(() => {
    if (document !== undefined) {
      setOrigin(document.location.origin);
    }
  }, []);

  if (data.length <= 0 || i18n.language === "en") {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Typography color={color} weight="medium" variant="h3">
          {t("solutionInstitution.partners.title")}
        </Typography>
        <div className={styles.subtitle}>
          <Typography color="black">
            {t("solutionInstitution.partners.subtitle")}
          </Typography>
        </div>

        <div className={styles.logos}>
          {data.map((partner, index) => {
            const link = partner.link || partner.social;

            return (
              <div className={styles.logo}>
                <a target="_blank" rel="noreferrer" href={link || undefined}>
                  <img
                    alt={`partner${index + 1}`}
                    src={`${origin}/assets/partners/${partner.image}`}
                  />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

SolutionPartners.propTypes = {
  color: PropTypes.oneOf(["purple", "red", "green", "yellow"]).isRequired,
};

export default SolutionPartners;
